import React from "react"

export const SanityIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="8.00098" cy="8" r="8" fill="#B7B5BD" />
      <path
        opacity="0.75"
        d="M10.1254 9.61902C10.875 10.0969 11.2145 10.7716 11.2145 11.7415C10.578 12.5427 9.48895 12.9784 8.20187 12.9784C6.03787 12.9784 4.4962 11.9101 4.1709 10.0688H6.25003C6.51876 10.9122 7.22595 11.3057 8.18772 11.3057C9.33337 11.3198 10.1113 10.7013 10.1254 9.61902Z"
        fill="white"
      />
      <path
        opacity="0.75"
        d="M6.06603 6.27368C5.35884 5.852 4.94867 5.09298 4.97696 4.27773C5.58514 3.4906 6.63178 3.0127 7.90472 3.0127C10.1253 3.0127 11.3982 4.17934 11.7235 5.80984H9.71512C9.48882 5.16326 8.93721 4.65725 7.933 4.65725C6.84394 4.6713 6.10846 5.28977 6.06603 6.27368Z"
        fill="white"
      />
      <path
        d="M4.97688 4.29175C4.97688 5.62707 5.81137 6.42826 7.48033 6.84994L9.2483 7.25756C10.8324 7.62302 11.7942 8.5226 11.7942 9.98442C11.8083 10.6169 11.5962 11.2354 11.2143 11.7414C11.2143 10.2796 10.4505 9.49246 8.62597 9.01456L6.88629 8.62099C5.48606 8.31176 4.41113 7.56679 4.41113 5.97847C4.41113 5.37406 4.60915 4.76965 4.97688 4.29175Z"
        fill="white"
      />
    </svg>
  )
}
