import React from "react"

export const DatoCmsIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9811 1.18518H8.78502C12.7693 1.18518 16.0007 4.36972 16.0007 8.29629C16.0007 12.2229 12.7693 15.4074 8.78502 15.4074H2.9811C2.19679 15.4074 1.56934 14.789 1.56934 14.0161V2.57648C1.56934 1.80354 2.19679 1.18518 2.9811 1.18518ZM5.23992 8.29629C5.23992 10.2441 6.83992 11.79 8.78502 11.79C10.7301 11.79 12.3301 10.2132 12.3301 8.29629C12.3301 6.34847 10.7615 4.80257 8.78502 4.80257C6.80855 4.80257 5.23992 6.37938 5.23992 8.29629Z"
        fill="#D9D7E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41274 1.18518H7.21666C11.201 1.18518 14.4324 4.36972 14.4324 8.29629C14.4324 12.2229 11.201 15.4074 7.21666 15.4074H1.41274C0.628428 15.4074 0.000976562 14.789 0.000976562 14.0161V2.57648C0.000976562 1.80354 0.628428 1.18518 1.41274 1.18518ZM3.67157 8.29629C3.67157 10.2441 5.27156 11.79 7.21666 11.79C9.16176 11.79 10.7618 10.2132 10.7618 8.29629C10.7618 6.34847 9.19313 4.80257 7.21666 4.80257C5.24019 4.80257 3.67157 6.37938 3.67157 8.29629Z"
        fill="#B7B5BD"
      />
    </svg>
  )
}
