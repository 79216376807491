import React from "react"

export const DiscordIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3333 15.3332L9.83333 11.9999L10.2533 13.3332L1.66667 13.3332C0.746666 13.3332 -1.10036e-06 12.5865 -1.01993e-06 11.6665L-1.45705e-07 1.66654C-6.52757e-08 0.746544 0.746667 -0.000122005 1.66667 -0.000121925L11.6667 -0.00012105C12.5867 -0.00012097 13.3333 0.746546 13.3333 1.66655L13.3333 15.3332ZM6.66667 3.86655C4.88 3.86654 3.62667 4.63321 3.62667 4.63321C4.31333 4.01988 5.51333 3.66654 5.51333 3.66654L5.4 3.55321C4.27333 3.57321 3.25333 4.35321 3.25333 4.35321C2.10667 6.74654 2.18 8.81321 2.18 8.81321C3.11333 10.0199 4.5 9.93321 4.5 9.93321L4.97333 9.33321C4.14 9.15321 3.61333 8.41321 3.61333 8.41321C3.61333 8.41321 4.86667 9.26654 6.66667 9.26654C8.46667 9.26654 9.72 8.41321 9.72 8.41321C9.72 8.41321 9.19333 9.15321 8.36 9.33321L8.83333 9.93321C8.83333 9.93321 10.22 10.0199 11.1533 8.81321C11.1533 8.81321 11.2267 6.74655 10.08 4.35321C10.08 4.35321 9.06 3.57321 7.93333 3.55321L7.82 3.66655C7.82 3.66655 9.02 4.01988 9.70667 4.63321C9.70667 4.63321 8.45333 3.86655 6.66667 3.86655ZM5.28667 6.39321C5.72 6.39321 6.07333 6.77321 6.06667 7.23988C6.06667 7.69988 5.72 8.08654 5.28667 8.08654C4.86 8.08654 4.51333 7.69988 4.51333 7.23988C4.51333 6.77321 4.85333 6.39321 5.28667 6.39321ZM8.06667 6.39321C8.5 6.39321 8.84667 6.77321 8.84667 7.23988C8.84667 7.69988 8.5 8.08654 8.06667 8.08654C7.64 8.08654 7.29333 7.69988 7.29333 7.23988C7.29333 6.77321 7.63333 6.39321 8.06667 6.39321Z"
        fill="#B7B5BD"
      />
    </svg>
  )
}
