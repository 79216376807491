import React from "react"

export const CosmicIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.19972 5.81097C6.577 5.49173 6.92526 5.17249 7.30253 4.88228C7.01232 4.67913 6.75113 4.505 6.48993 4.33087C6.1707 4.59206 5.85146 4.88228 5.53223 5.17249C5.85146 5.28858 6.08363 5.52075 6.19972 5.81097Z"
        fill="#B7B5BD"
      />
      <path
        d="M5.26801 7.26196C5.95722 7.26378 6.51734 6.73252 6.51908 6.07537C6.52081 5.41822 5.9635 4.88402 5.27429 4.8822C4.58508 4.88038 4.02496 5.41163 4.02323 6.06879C4.0215 6.72594 4.5788 7.26014 5.26801 7.26196Z"
        fill="#B7B5BD"
      />
      <path
        d="M6.40257 12.7471C4.6903 13.6758 3.21021 13.9079 2.54272 13.2695C1.61403 12.3698 2.45565 10.0191 4.48715 7.6393C4.16792 7.55223 3.90672 7.32006 3.76162 7.02985C0.801428 10.3383 -0.53356 13.6758 0.772406 14.9237C1.8462 15.9685 4.42911 15.2719 7.27322 13.4146C6.983 13.2114 6.69279 12.9792 6.40257 12.7471Z"
        fill="#B7B5BD"
      />
      <path
        d="M8.43394 4.82421C8.521 4.50497 8.75317 4.24378 9.04339 4.09867C5.61885 1.28359 2.13627 0.0356651 0.830308 1.28359C-0.243486 2.32836 0.453029 4.79518 2.39747 7.5232C2.62964 7.23299 2.86181 6.97179 3.09398 6.68158C2.13627 5.02736 1.87508 3.6053 2.54258 2.93781C3.50028 2.03815 5.93809 2.87977 8.43394 4.82421Z"
        fill="#B7B5BD"
      />
      <path
        d="M10.7554 5.63678C10.6393 5.92699 10.3781 6.15917 10.0879 6.27525C10.5232 6.71057 10.9295 7.11688 11.2778 7.5522C11.4809 7.291 11.6841 7.02981 11.8292 6.76862C11.5099 6.39134 11.1327 6.01406 10.7554 5.63678Z"
        fill="#B7B5BD"
      />
      <path
        d="M10.0594 6.79767C10.756 6.79767 11.3074 6.27528 11.3074 5.60779C11.3074 4.94029 10.756 4.41791 10.0594 4.41791C9.36293 4.41791 8.81152 4.94029 8.81152 5.60779C8.81152 6.24626 9.36293 6.79767 10.0594 6.79767Z"
        fill="#B7B5BD"
      />
      <path
        d="M13.2803 2.9668C14.209 3.86646 13.3674 6.2172 11.3359 8.59696C11.6551 8.68403 11.9163 8.9162 12.0614 9.20641C14.9926 5.89797 16.3276 2.53148 15.0216 1.28355C13.9478 0.267804 11.3649 0.964319 8.5498 2.82169C8.84002 3.02484 9.13023 3.25701 9.42045 3.48919C11.1327 2.5605 12.6128 2.32833 13.2803 2.9668Z"
        fill="#B7B5BD"
      />
      <path
        d="M9.82718 10.2222C9.39185 10.6285 8.92751 11.0057 8.49219 11.354C8.7824 11.5572 9.0436 11.7313 9.33381 11.9054C9.74011 11.5862 10.1174 11.2379 10.5237 10.8606C10.2045 10.7446 9.97228 10.5124 9.82718 10.2222Z"
        fill="#B7B5BD"
      />
      <path
        d="M10.5409 8.98936C9.85165 8.98746 9.29146 9.51865 9.28965 10.1758C9.28784 10.833 9.84509 11.3672 10.5343 11.3691C11.2235 11.371 11.7837 10.8398 11.7855 10.1827C11.7873 9.52553 11.2301 8.99126 10.5409 8.98936Z"
        fill="#B7B5BD"
      />
      <path
        d="M5.73542 9.99001C5.3001 9.55469 4.8938 9.14839 4.54555 8.71307C4.3424 8.97427 4.13925 9.23546 3.99414 9.49665C4.3424 9.87393 4.69065 10.2512 5.06793 10.6285C5.18402 10.3383 5.41619 10.1061 5.73542 9.99001Z"
        fill="#B7B5BD"
      />
      <path
        d="M13.4259 8.74213C13.1937 9.03234 12.9616 9.29353 12.7294 9.58375C13.6871 11.238 13.9483 12.631 13.2518 13.2985C12.2941 14.1982 9.85628 13.3565 7.36043 11.4411C7.27336 11.7604 7.04119 12.0216 6.75098 12.1667C10.2045 14.9817 13.6871 16.2587 14.9931 15.0108C16.0669 13.937 15.3704 11.4411 13.4259 8.74213Z"
        fill="#B7B5BD"
      />
      <path
        d="M7.01752 10.6442C7.01925 9.98703 6.46193 9.45284 5.77272 9.45103C5.08351 9.44923 4.5234 9.98049 4.52168 10.6376C4.51995 11.2948 5.07727 11.829 5.76648 11.8308C6.45569 11.8326 7.0158 11.3013 7.01752 10.6442Z"
        fill="#B7B5BD"
      />
    </svg>
  )
}
