import {
  DocsIcon,
  PluginsIcon,
  RecipesIcon,
  ThemesIcon,
  UnionIcon,
  DaysIcon,
  SwagStoreIcon,
  WebinarsIcon,
  GatsbyCloudIcon,
  IntegrationsIcon,
  CloudDocumentation,
  PartnersIcon,
  ConciergeIcon,
  DrupalIcon,
  SanityIcon,
  ContentfulIcon,
  DatoCmsIcon,
  CosmicIcon,
  WordpressIcon,
  SparkleIcon,
  TwitchIcon,
  DiscordIcon,
  YouTubeIcon,
  ContentstackIcon,
  StrapiIcon,
  KontentIcon,
  PricingIcon,
  ShopifyIcon,
  UseCasesIcon,
  HowToGuideIcon,
  ReferenceGuideIcon,
  ConceptualGuideIcon,
  TutorialGuideIcon,
} from "../shared/icons"

// IDENTIFIERS

export const Identifiers = {
  GetStarted: `getStarted`,
  LogIn: `logIn`,
  Cloud: `cloud`,
}

// DETAILS

const docsDocumentation = [
  [
    {
      heading: {
        text: `Get Started`,
      },
      items: [
        {
          text: `Documentation`,
          url: `/docs/`,
        },
        {
          text: `Quick Start`,
          url: `/docs/quick-start/`,
        },
        {
          text: `Tutorial`,
          url: `/docs/tutorial/`,
        },
        {
          text: `Cheat Sheet`,
          url: `/docs/cheat-sheet/`,
        },
      ],
    },
    {
      heading: {
        text: `Guides`,
      },
      items: [
        {
          text: `How-To Guides`,
          url: `/docs/how-to/`,
        },
        {
          text: `Reference Guides`,
          url: `/docs/reference/`,
        },
        {
          text: `Conceptual Guides`,
          url: `/docs/conceptual/`,
        },
      ],
    },
  ],
]

const pluginsLinks = [
  [
    {
      items: [
        {
          text: `Documentation`,
          url: `/docs/plugins/`,
        },
      ],
    },
    {
      items: [
        {
          text: `Library`,
          url: `/plugins/`,
        },
      ],
    },
  ],
]

const featuresComparison = [
  [
    {
      heading: {
        text: `Jamstack`,
      },
      items: [
        {
          text: `Gatsby vs Next`,
          url: `/features/jamstack/gatsby-vs-nextjs/`,
        },
        {
          text: `Gatsby vs Jekyll vs Hugo`,
          url: `/features/jamstack/gatsby-vs-jekyll-vs-hugo/`,
        },
        {
          text: `Gatsby vs Nuxt`,
          url: `/features/jamstack/gatsby-vs-nuxtjs/`,
        },
      ],
    },
    {
      heading: {
        text: `Traditional CMS`,
      },
      items: [
        {
          text: `Wordpress`,
          url: `/features/cms/gatsby-vs-wordpress/`,
          Icon: WordpressIcon,
        },
        {
          text: `Drupal`,
          url: `/features/cms/gatsby-vs-drupal/`,
          Icon: DrupalIcon,
        },
      ],
    },
  ],
]

const cloudIntegrations = [
  [
    {
      items: [
        {
          text: `Wordpress`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360053098633-Connecting-to-WordPress`,
          Icon: WordpressIcon,
        },
        {
          text: `Drupal`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324514-Connecting-to-Drupal`,
          Icon: DrupalIcon,
        },
        {
          text: `Strapi`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324714-Connecting-to-Strapi`,
          Icon: StrapiIcon,
        },
      ],
    },
    {
      items: [
        {
          text: `Contentful`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052076554-Connecting-to-Contentful`,
          Icon: ContentfulIcon,
        },
        {
          text: `Dato CMS`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324454-Connecting-to-DatoCMS`,
          Icon: DatoCmsIcon,
        },
        {
          text: `Kontent`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324654-Connecting-to-Kontent`,
          Icon: KontentIcon,
        },
      ],
    },
    {
      items: [
        {
          text: `Sanity`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324694-Connecting-to-Sanity-io`,
          Icon: SanityIcon,
        },
        {
          text: `Cosmic`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052077034-Connecting-to-Cosmic`,
          Icon: CosmicIcon,
        },
        {
          text: `Contentstack`,
          url: `https://support.gatsbyjs.com/hc/en-us/articles/360052324434-Connecting-to-Contentstack`,
          Icon: ContentstackIcon,
        },
      ],
    },
  ],
  {
    items: [
      {
        text: `Explore all Integrations`,
        url: `https://support.gatsbyjs.com/hc/en-us/sections/360011112314-Connecting-to-a-Content-Management-System`,
      },
    ],
  },
]

const useCaseDetails = [
  [
    {
      heading: {
        text: `By Industry`,
      },
      items: [
        {
          text: `Software & SaaS`,
          url: `/use-cases/software-saas/`,
        },
        {
          text: `Consumer Finance & Insurance`,
          url: `/use-cases/finance-insurance/`,
        },
        {
          text: `E-commerce`,
          url: `/use-cases/e-commerce/`,
        },
        {
          text: `Public Interest Organizations`,
          url: `/use-cases/public-interest-organization/`,
        },
        {
          text: `Content & Media`,
          url: `/use-cases/content-media/`,
        },
      ],
    },
    {
      heading: {
        text: `By Technology`,
      },
      items: [
        {
          text: `Wordpress`,
          url: `/use-cases/wordpress/`,
          Icon: WordpressIcon,
        },
        {
          text: `Contentful`,
          url: `/use-cases/contentful/`,
          Icon: ContentfulIcon,
        },
        {
          text: `Drupal`,
          url: `/use-cases/drupal/`,
          Icon: DrupalIcon,
        },
        {
          text: `Shopify`,
          url: `/use-cases/shopify/`,
          Icon: ShopifyIcon,
        },
      ],
    },
  ],
]

const eventsWebinars = [
  [
    {
      heading: {
        text: `Latest webinars`,
      },
      items: [
        {
          text: `This Changes Everything: Incremental Builds in Gatsby`,
          url: `/incrbuilds-webinar/`,
        },
        {
          text: `Matter Supply: Just Do It 2.0`,
          url: `/matter-supply-webinar`,
        },
      ],
    },
  ],
]

const communityGuides = [
  [
    {
      heading: {
        text: `Contributing`,
      },
      items: [
        {
          text: `Why contribute`,
          url: `/contributing/`,
        },
        {
          text: `How to contribute`,
          url: `/contributing/how-to-contribute/`,
        },
      ],
    },
    {
      items: [
        {
          text: `Code of Conduct`,
          url: `/contributing/code-of-conduct/`,
        },
        {
          text: `Style Guide`,
          url: `/contributing/gatsby-style-guide/`,
        },
      ],
    },
  ],
  {
    items: [
      {
        text: `Twitch`,
        url: `https://www.twitch.tv/gatsbyjs`,
        Icon: TwitchIcon,
      },
      {
        text: `Discord`,
        url: `https://gatsby.dev/discord`,
        Icon: DiscordIcon,
      },
      {
        text: `YouTube`,
        url: `https://www.youtube.com/gatsbyjs`,
        Icon: YouTubeIcon,
      },
    ],
  },
]

// DROPDOWNS

const docs = {
  items: [
    {
      text: `Documentation`,
      url: `/docs/`,
      description: `Start building with Gatsby and its ecosystem tools`,
      Icon: DocsIcon,
      details: docsDocumentation,
    },
  ],
  bgColor: `purple`,
}

const features = {
  items: [
    {
      text: `Feature Comparison`,
      url: `/features/`,
      description: `See how Gatsby compares to other JAMstack frameworks and traditional content management systems (CMS)`,
      Icon: UnionIcon,
      details: featuresComparison,
    },
    {
      text: `Plugins`,
      url: `/docs/plugins/`,
      description: `RSS feed? SEO? Shopify integration? There’s a plugin for that!`,
      Icon: PluginsIcon,
      colored: true,
      details: pluginsLinks,
    },
    {
      text: `Recipes`,
      url: `/docs/recipes/`,
      Icon: RecipesIcon,
      colored: true,
    },
    {
      text: `Themes`,
      url: `/docs/themes/`,
      Icon: ThemesIcon,
      colored: true,
    },
  ],
  bgColor: `green`,
}

const useCases = {
  items: [
    {
      text: `Use Cases`,
      url: `/use-cases/`,
      description: `Explore the many ways to use Gatsby:`,
      Icon: UseCasesIcon,
      details: useCaseDetails,
    },
    {
      text: `Webinars`,
      url: `/resources/webinars/`,
      description: `Learn more with webinars on demand`,
      Icon: WebinarsIcon,
      details: eventsWebinars,
      colored: true,
    },
  ],
  bgColor: `magenta`,
}

const cloud = {
  items: [
    {
      text: `Why Gatsby Cloud?`,
      url: `/cloud/`,
      Icon: GatsbyCloudIcon,
    },
    {
      text: `Integrations`,
      url: `/integrations/`,
      description: `Gatsby Cloud is the best way to use Gatsby's thriving plugin ecosystem:`,
      Icon: IntegrationsIcon,
      details: cloudIntegrations,
    },
    {
      text: `Pricing`,
      url: `/pricing/`,
      colored: true,
      Icon: PricingIcon,
    },
    {
      text: `Knowledge Base`,
      url: `https://support.gatsbyjs.com/hc`,
      Icon: CloudDocumentation,
      colored: true,
    },
    {
      text: `Concierge`,
      url: `/concierge/`,
      description: `Dedicated Gatsby support to turbocharge your site`,
      Icon: ConciergeIcon,
      colored: true,
    },
  ],
  bgColor: `blue`,
}

const community = {
  items: [
    {
      text: `Community`,
      url: `/contributing/community/`,
      Icon: PartnersIcon,
      description: `We’re so glad you want to help! Here’s how to get started:`,
      details: communityGuides,
    },
    {
      text: `Showcase`,
      url: `/showcase/`,
      Icon: SparkleIcon,
      description: `Check out what the community is building`,
      colored: true,
    },
    {
      text: `GatsbyConf`,
      url: `https://gatsbyconf.com/`,
      description: `Join us for GatsbyConf - March 2-3, 2021!`,
      Icon: DaysIcon,
      colored: true,
    },
    {
      text: `Swag Store`,
      url: `https://store.gatsbyjs.org/`,
      Icon: SwagStoreIcon,
      description: `Get free swag by contributing to Gatsby!`,
      colored: true,
    },
  ],
  bgColor: `orange`,
}

// SECTIONS

export const mainSection = [
  {
    text: `Docs`,
    dropdown: docs,
  },
  {
    url: `/plugins/`,
    text: `Plugins`,
    hiddenOnMobile: true,
  },
  {
    text: `Features`,
    dropdown: features,
  },
  {
    text: `Use Cases`,
    dropdown: useCases,
  },
  {
    text: `Community`,
    dropdown: community,
  },
  {
    url: `/blog/`,
    text: `Blog`,
    Icon: DocsIcon,
  },
]

export const docsSection = [
  {
    url: `/docs/tutorial/`,
    text: `Tutorial`,
    Icon: TutorialGuideIcon,
    showIconOnDesktop: true,
    highlightColor: ({ theme }) => ({
      ":hover, :focus, &.active": { background: theme.colors.orange[10] },
    }),
  },
  {
    url: `/docs/how-to/`,
    text: `How-to Guides`,
    Icon: HowToGuideIcon,
    showIconOnDesktop: true,
    highlightColor: ({ theme }) => ({
      ":hover, :focus, &.active": { background: theme.colors.teal[10] },
    }),
  },
  {
    url: `/docs/reference/`,
    text: `Reference`,
    Icon: ReferenceGuideIcon,
    showIconOnDesktop: true,
    highlightColor: ({ theme }) => ({
      ":hover, :focus, &.active": { background: theme.colors.magenta[10] },
    }),
  },
  {
    url: `/docs/conceptual/`,
    text: `Conceptual Guide`,
    Icon: ConceptualGuideIcon,
    showIconOnDesktop: true,
    highlightColor: ({ theme }) => ({
      ":hover, :focus, &.active": { background: theme.colors.blue[10] },
    }),
  },
]

const docSwitcher = {
  items: [
    {
      text: `v3`,
      url: `https://www.gatsbyjs.com/docs/`,
    },
    {
      text: `v2`,
      url: `https://v2.gatsbyjs.com/docs/`,
    },
    {
      text: `v1`,
      url: `https://v1.gatsbyjs.org/docs/`,
    },
  ],
  bgColor: `purple`,
  dropdownWidth: `10rem`,
}

export const sideDocsSection = [
  {
    text: `Quick Start`,
    url: `/docs/quick-start/`,
    identifier: Identifiers.GetStarted,
  },
  {
    text: `v2`,
    dropdown: docSwitcher,
  },
]

export const sideSection = [
  {
    text: `Gatsby Cloud`,
    dropdown: cloud,
    identifier: Identifiers.Cloud,
  },
  {
    text: `Get Started`,
    url: `/get-started/`,
    identifier: Identifiers.GetStarted,
  },
]

const topLine = [
  {
    url: `/support/`,
    text: `Support`,
    buttonSize: "S",
  },
  {
    url: `/contact-us/`,
    text: `Contact`,
    buttonSize: "S",
  },
  {
    url: `/dashboard/login/`,
    text: `Log in`,
    identifier: Identifiers.LogIn,
    buttonSize: "S",
  },
]

export const docsTopLineData = [
  {
    url: `/`,
    text: `Gatsbyjs.com`,
    buttonSize: "S",
  },
  {
    url: `/cloud/`,
    text: `Gatsby Cloud`,
    buttonSize: "S",
  },
  ...topLine,
]

export const topLineData = topLine

export const featuredMobileItems = {
  getStarted: sideSection[1],
  contact: topLine[1],
  logIn: topLine[2],
}
