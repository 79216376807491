import React from "react"

export function KontentIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 150 150" fill="none" {...props}>
      <title>Kontent</title>
      <path
        fill="#B7B5BD"
        d="M36.34,16.7V73.4a2.15,2.15,0,0,1-1.74,2.12c-7.23,1.37-34.46,5-34.46-18V17c0-22.32,36.2-22.82,36.2-.26"
      />
      <path
        fill="#B7B5BD"
        d="M85.88,55.79V112.5a2.14,2.14,0,0,1-1.75,2.11c-7.23,1.38-34.46,5-34.46-18V56.05c0-22.31,36.21-22.81,36.21-.26"
      />
      <path
        fill="#B7B5BD"
        d="M135.41,90.1v56.71a2.15,2.15,0,0,1-1.75,2.12c-7.23,1.37-34.46,5-34.46-18V90.36c0-22.31,36.21-22.81,36.21-.26"
      />
    </svg>
  )
}
