import React from "react"

export const ReferenceGuideIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.1379 15.5C15.6162 15.2238 16.3916 15.2238 16.87 15.5L25.1383 20.2733C25.6166 20.5494 25.6166 20.9971 25.1383 21.2732L16.87 26.0465C16.3916 26.3226 15.6162 26.3226 15.1379 26.0465L6.86953 21.2732C6.39123 20.9971 6.39123 20.5494 6.86953 20.2733L15.1379 15.5Z"
      fill="#F6EDFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0039 16.9999L9.46766 20.7732L16.0039 24.5466L22.5401 20.7732L16.0039 16.9999ZM16.87 15.5C16.3916 15.2238 15.6162 15.2238 15.1379 15.5L6.86953 20.2733C6.39123 20.5494 6.39123 20.9971 6.86953 21.2732L15.1379 26.0465C15.6162 26.3226 16.3916 26.3226 16.87 26.0465L25.1383 21.2732C25.6166 20.9971 25.6166 20.5494 25.1383 20.2733L16.87 15.5Z"
      fill="#D9BAE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11.9999L9.46376 15.7732L16 19.5466L22.5362 15.7732L16 11.9999ZM16.866 10.5C16.3877 10.2238 15.6123 10.2238 15.134 10.5L6.86563 15.2733C6.38732 15.5494 6.38732 15.9971 6.86563 16.2732L15.134 21.0465C15.6123 21.3226 16.3877 21.3226 16.866 21.0465L25.1344 16.2732C25.6127 15.9971 25.6127 15.5494 25.1344 15.2733L16.866 10.5Z"
      fill="#BC027F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0039 6.99987L9.46766 10.7732L16.0039 14.5466L22.5401 10.7732L16.0039 6.99987ZM16.87 5.49997C16.3916 5.22384 15.6162 5.22384 15.1379 5.49997L6.86953 10.2733C6.39123 10.5494 6.39123 10.9971 6.86953 11.2732L15.1379 16.0465C15.6162 16.3226 16.3916 16.3226 16.87 16.0465L25.1383 11.2732C25.6166 10.9971 25.6166 10.5494 25.1383 10.2733L16.87 5.49997Z"
      fill="#663399"
    />
  </svg>
)
