import React from "react"

export const RecipesIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18.0832 21.8327C18.094 21.7844 18.1369 21.75 18.1864 21.75H21.8136C21.8631 21.75 21.906 21.7844 21.9168 21.8327L22.6489 21.67L21.9168 21.8327L22.7917 25.7699C23.1886 27.5558 21.8295 29.25 20 29.25C18.1705 29.25 16.8114 27.5558 17.2083 25.7699L18.0832 21.8327Z"
      stroke="#59C156"
      strokeWidth="1.5"
    />
    <path
      d="M20 18V29.5"
      stroke="#59C156"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <circle cx="13" cy="24" r="1" fill="#D9BAE8" />
    <path
      d="M23.25 8C23.25 6.20507 21.7949 4.75 20 4.75H10.6667C9.35464 4.75 8.17129 5.5389 7.66667 6.75L6 10.75C5.10803 12.8907 6.68087 15.25 9 15.25H14.1716C14.9009 15.25 15.6004 15.5397 16.1161 16.0555L16.9445 16.8839C17.179 17.1183 17.4969 17.25 17.8284 17.25H22C22.6904 17.25 23.25 16.6904 23.25 16V8Z"
      fill="#F6EDFA"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <path
      d="M9.09739 9.10608L9.4805 8.21216C9.79566 7.47679 10.5187 7 11.3188 7H15.5C16.0523 7 16.5 7.44772 16.5 8V9.5C16.5 10.0523 16.0523 10.5 15.5 10.5H10.0165C9.29862 10.5 8.81459 9.76595 9.09739 9.10608Z"
      fill="white"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <path
      d="M22.5 10.5H21"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 13H21"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 4C17 3.44772 17.4477 3 18 3H20C20.5523 3 21 3.44772 21 4V5H17V4Z"
      fill="#663399"
    />
    <circle cx="26" cy="20" r="2" fill="#59C156" />
  </svg>
)
