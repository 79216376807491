import React from "react"

export const ContentfulIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.13956 11.4857V11.4857C4.34629 10.6947 3.90045 9.6205 3.90045 8.50025C3.90045 7.38001 4.34629 6.30582 5.13956 5.51482V5.51482C5.78299 4.87138 5.78299 3.82816 5.13956 3.18473C4.49612 2.54129 3.4529 2.54129 2.80946 3.18473C1.41514 4.60334 0.631118 6.51114 0.625 8.50025C0.64478 10.4866 1.42679 12.3895 2.80946 13.8158C3.22569 14.232 3.83236 14.3946 4.40095 14.2422C4.96953 14.0899 5.41364 13.6457 5.56599 13.0772C5.71834 12.5086 5.55579 11.9019 5.13956 11.4857V11.4857Z"
        stroke="#B7B5BD"
      />
      <path
        d="M5.13982 5.51456V5.51456C5.93082 4.72129 7.00501 4.27545 8.12525 4.27545C9.2455 4.27545 10.3197 4.72129 11.1107 5.51456V5.51456C11.7541 6.15799 12.7973 6.15799 13.4408 5.51456C14.0842 4.87112 14.0842 3.8279 13.4408 3.18446C12.0222 1.79014 10.1144 1.00612 8.12525 1C6.13888 1.01978 4.236 1.80179 2.80973 3.18446C2.16629 3.8279 2.16629 4.87112 2.80973 5.51456C3.45316 6.15799 4.49638 6.15799 5.13982 5.51456V5.51456Z"
        stroke="#B7B5BD"
      />
      <path
        d="M11.1107 11.4855V11.4855C10.3197 12.2788 9.2455 12.7247 8.12525 12.7247C7.00501 12.7247 5.93082 12.2788 5.13982 11.4855V11.4855C4.49638 10.8421 3.45316 10.8421 2.80973 11.4855C2.16629 12.129 2.16629 13.1722 2.80973 13.8156C4.22834 15.21 6.13614 15.994 8.12525 16.0001C10.1116 15.9803 12.0145 15.1983 13.4408 13.8156C13.857 13.3994 14.0196 12.7927 13.8672 12.2242C13.7149 11.6556 13.2707 11.2115 12.7022 11.0591C12.1336 10.9068 11.5269 11.0693 11.1107 11.4855V11.4855Z"
        stroke="#B7B5BD"
      />
    </svg>
  )
}
