import React from "react"

export const IntegrationsIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <line
      x1="17.25"
      y1="18"
      x2="17.25"
      y2="22"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <line
      x1="20.0556"
      y1="10.8839"
      x2="23.5911"
      y2="7.34838"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <line
      x1="10.7358"
      y1="9.59828"
      x2="14.8316"
      y2="12.4662"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <line
      x1="19.7358"
      y1="15.5983"
      x2="23.8316"
      y2="18.4662"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <line
      x1="7.59076"
      y1="23.3484"
      x2="14.5908"
      y2="16.3484"
      stroke="#159BF3"
      strokeWidth="1.5"
    />
    <circle
      cx="17"
      cy="14"
      r="4"
      fill="#D9BAE8"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <circle
      cx="25"
      cy="19"
      r="2"
      fill="#663399"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <circle
      cx="9"
      cy="8.5"
      r="2"
      fill="white"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <circle
      cx="17.25"
      cy="24"
      r="2"
      fill="white"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <circle
      cx="6"
      cy="25"
      r="2"
      fill="#159BF3"
      stroke="#159BF3"
      strokeWidth="1.5"
    />
    <circle
      cx="24"
      cy="7"
      r="2"
      fill="white"
      stroke="#663399"
      strokeWidth="1.5"
    />
  </svg>
)
