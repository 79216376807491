import React from "react"

export const DaysIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g>
      <path d="M15 5C9.47715 5 5 9.47715 5 15H15V5Z" fill="#F1DEFA" />
      <path
        d="M24.25 23.1893L16.8107 15.75H24.25V23.1893Z"
        fill="#D459AB"
        stroke="#D459AB"
        strokeWidth="1.5"
      />
      <circle cx="20" cy="10" r="5" fill="#663399" />
      <line x1="14" y1="15" x2="6" y2="15" stroke="#663399" strokeWidth="1.5" />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="10"
        height="20"
      >
        <path
          d="M5 15C5 20.5228 9.47715 25 15 25V5C9.47715 5 5 9.47715 5 15Z"
          fill="#663399"
        />
      </mask>
      <g>
        <path d="M8 21.7783L15 14.7783" stroke="#663399" strokeWidth="1.5" />
      </g>
      <path
        d="M14.25 24.22C9.49211 23.8383 5.75 19.8561 5.75 15C5.75 10.1439 9.49211 6.16173 14.25 5.77997V24.22Z"
        stroke="#663399"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)
