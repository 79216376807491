import React from "react"

export function StrapiIcon(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6159 10.8743V5.71174C10.6159 5.52787 10.4669 5.37881 10.283 5.37881H5.12643V0.0498657H15.6061C15.79 0.0498657 15.9391 0.198922 15.9391 0.382791V10.8743H10.6159Z"
        fill="#B7B5BD"
      />
      <path
        opacity="0.404989"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12643 0.0498657V5.37881H0.204854C0.112919 5.37881 0.0383911 5.30429 0.0383911 5.21235C0.0383911 5.16823 0.0559042 5.12592 0.0870828 5.09471L5.12643 0.0498657Z"
        fill="#B7B5BD"
      />
      <path
        opacity="0.404989"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6159 15.8011V10.8743H15.9391L10.9002 15.9187C10.8352 15.9838 10.7298 15.9838 10.6648 15.9188C10.6335 15.8876 10.6159 15.8452 10.6159 15.8011Z"
        fill="#B7B5BD"
      />
      <path
        opacity="0.404989"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12643 5.37881H10.4495C10.5414 5.37881 10.6159 5.45334 10.6159 5.54528V10.8743H5.45936C5.27549 10.8743 5.12643 10.7252 5.12643 10.5414V5.37881Z"
        fill="#B7B5BD"
      />
    </svg>
  )
}
