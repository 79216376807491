import React from "react"

export const PricingIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M25.375 18.5V21C25.375 21.3315 25.2433 21.6495 25.0089 21.8839C24.7745 22.1183 24.4565 22.25 24.125 22.25H7.875C7.54348 22.25 7.22554 22.1183 6.99112 21.8839C6.7567 21.6495 6.625 21.3315 6.625 21V18.5C7.28804 18.5 7.92393 18.2366 8.39277 17.7678C8.86161 17.2989 9.125 16.663 9.125 16C9.125 15.337 8.86161 14.7011 8.39277 14.2322C7.92393 13.7634 7.28804 13.5 6.625 13.5V11C6.625 10.6685 6.7567 10.3505 6.99112 10.1161C7.22554 9.8817 7.54348 9.75 7.875 9.75H24.125C24.4565 9.75 24.7745 9.8817 25.0089 10.1161C25.2433 10.3505 25.375 10.6685 25.375 11V13.5C24.712 13.5 24.0761 13.7634 23.6072 14.2322C23.1384 14.7011 22.875 15.337 22.875 16C22.875 16.663 23.1384 17.2989 23.6072 17.7678C24.0761 18.2366 24.712 18.5 25.375 18.5Z"
      fill="#F6EDFA"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.875 14.75C13.5654 14.75 14.125 14.1904 14.125 13.5C14.125 12.8096 13.5654 12.25 12.875 12.25C12.1846 12.25 11.625 12.8096 11.625 13.5C11.625 14.1904 12.1846 14.75 12.875 14.75Z"
      stroke="#159BF3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.75 19.75C20.4404 19.75 21 19.1904 21 18.5C21 17.8096 20.4404 17.25 19.75 17.25C19.0596 17.25 18.5 17.8096 18.5 18.5C18.5 19.1904 19.0596 19.75 19.75 19.75Z"
      stroke="#159BF3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.125 12.875L12.875 19.125"
      stroke="#159BF3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
