import React from "react"

export const SwagStoreIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      x="14.4645"
      y="8.93951"
      width="7.80744"
      height="6.49125"
      rx="0.75"
      transform="rotate(-15 14.4645 8.93951)"
      fill="#F67300"
      stroke="#F67300"
      strokeWidth="1.5"
    />
    <circle
      r="4.25"
      transform="matrix(-1 0 0 1 15.5 11.0098)"
      fill="#F6EDFA"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <path
      d="M20.625 24.3848C20.5508 24.3848 20.4783 24.4068 20.4167 24.448C20.355 24.4892 20.3069 24.5477 20.2785 24.6163C20.2502 24.6848 20.2427 24.7602 20.2572 24.8329C20.2717 24.9057 20.3074 24.9725 20.3598 25.0249C20.4123 25.0774 20.4791 25.1131 20.5518 25.1276C20.6246 25.142 20.7 25.1346 20.7685 25.1062C20.837 25.0778 20.8956 25.0298 20.9368 24.9681C20.978 24.9064 21 24.8339 21 24.7598C21 24.6603 20.9605 24.5649 20.8902 24.4946C20.8198 24.4243 20.7245 24.3848 20.625 24.3848Z"
      fill="#663399"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 24.3848C13.0508 24.3848 12.9783 24.4068 12.9167 24.448C12.855 24.4892 12.8069 24.5477 12.7785 24.6163C12.7502 24.6848 12.7427 24.7602 12.7572 24.8329C12.7717 24.9057 12.8074 24.9725 12.8598 25.0249C12.9123 25.0774 12.9791 25.1131 13.0518 25.1276C13.1246 25.142 13.2 25.1346 13.2685 25.1062C13.337 25.0778 13.3956 25.0298 13.4368 24.9681C13.478 24.9064 13.5 24.8339 13.5 24.7598C13.5 24.6603 13.4605 24.5649 13.3902 24.4946C13.3198 24.4243 13.2245 24.3848 13.125 24.3848"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 19.0138H20.6176C21.2865 19.0137 21.9362 18.7901 22.4635 18.3784C22.9907 17.9668 23.3653 17.3907 23.5276 16.7418L24.9776 11.9418C25.0053 11.8312 25.0073 11.7157 24.9837 11.6042C24.96 11.4926 24.9112 11.3879 24.8411 11.2981C24.7709 11.2082 24.6811 11.1356 24.5786 11.0856C24.4761 11.0356 24.3636 11.0097 24.2496 11.0098L9.5 11.0098"
      fill="white"
    />
    <path
      d="M11 19.0138H20.6176C21.2865 19.0137 21.9362 18.7901 22.4635 18.3784C22.9907 17.9668 23.3653 17.3907 23.5276 16.7418L24.9776 11.9418C25.0053 11.8312 25.0073 11.7157 24.9837 11.6042C24.96 11.4926 24.9112 11.3879 24.8411 11.2981C24.7709 11.2082 24.6811 11.1356 24.5786 11.0856C24.4761 11.0356 24.3636 11.0097 24.2496 11.0098L9.5 11.0098"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 22.1347H12.579C12.2247 22.1347 11.8818 22.0093 11.611 21.7807C11.3403 21.5521 11.1593 21.235 11.1 20.8857L8.463 7.3857C8.4035 7.03654 8.22236 6.71971 7.95167 6.49129C7.68097 6.26288 7.33819 6.13762 6.984 6.1377H6"
      stroke="#663399"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
