import React from "react"
import { generateId } from "../../shared/helpers"

export const GatsbyCloudIcon = ({ width = 32, height = 32, ...rest }) => {
  const gradientId = generateId()

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="10.3097" cy="11.8282" r="3.37661" fill="#159CF4" />
      <circle cx="9.60344" cy="16.812" r="3.01799" fill="white" />
      <circle cx="17.2403" cy="14.6116" r="5.38333" fill="white" />
      <rect
        x="9.60352"
        y="16.8303"
        width="8.20622"
        height="3.00042"
        fill="white"
      />
      <circle cx="11.6583" cy="14.2931" r="0.99225" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6316 21.0374L9.99109 21.0374C7.63289 21.0374 5.72119 19.1257 5.72119 16.7675C5.72119 14.4093 7.63289 12.4976 9.99109 12.4976C10.3399 12.4976 10.6789 12.5394 11.0034 12.6183C11.8605 10.0685 14.2702 8.23175 17.1089 8.23175C19.7951 8.23175 22.0972 9.87651 23.063 12.2139C22.5394 12.051 21.991 11.9445 21.4248 11.9015C20.5178 10.525 18.9583 9.6165 17.1865 9.6165C14.6053 9.6165 12.6652 11.5679 12.4055 13.2315C12.347 13.6064 12.2024 13.8523 11.9543 14.1151C11.7507 14.2484 11.5943 14.2797 11.4101 14.2484C11.285 14.2271 11.179 14.1826 11.0608 14.1329C10.8293 14.0356 10.5511 13.9187 9.9909 13.9187C8.4205 13.9187 7.14744 15.1917 7.14744 16.7621C7.14744 18.3325 8.4205 19.6056 9.9909 19.6056H13.4346C13.4541 20.0966 13.5213 20.5754 13.6316 21.0374Z"
        fill={`url(#${gradientId})`}
      />
      <path
        d="M13.3855 19.3366C13.3855 23.4618 16.7296 26.806 20.8548 26.806C24.9801 26.806 28.3242 23.4618 28.3242 19.3366C28.3242 15.2114 24.9801 11.8672 20.8548 11.8672C16.7296 11.8672 13.3855 15.2114 13.3855 19.3366Z"
        fill="white"
      />
      <circle cx="11.2579" cy="6.14066" r="0.947821" fill="#159BF3" />
      <circle cx="4.62311" cy="11.8276" r="0.947821" fill="#159BF3" />
      <circle cx="6.51911" cy="7.09022" r="0.947821" fill="#159BF3" />
      <path
        d="M25.6394 19.426H22.6394V20.2831H24.6965C24.3965 21.5688 23.4536 22.6403 22.2108 23.0688L17.2822 18.1403C17.7965 16.6403 19.2536 15.5688 20.9251 15.5688C22.2108 15.5688 23.3679 16.2117 24.0965 17.1974L24.7394 16.6403C23.8822 15.4831 22.5108 14.7117 20.9251 14.7117C18.6965 14.7117 16.8108 16.2974 16.3394 18.3974L21.9965 24.0546C24.0536 23.5403 25.6394 21.6546 25.6394 19.426Z"
        fill="white"
      />
      <path
        d="M16.2109 19.4688C16.2109 20.6688 16.6824 21.826 17.5824 22.726C18.4824 23.626 19.6824 24.0974 20.8395 24.0974L16.2109 19.4688Z"
        fill="white"
      />
      <path
        d="M20.9253 13.426C17.6253 13.426 14.9253 16.126 14.9253 19.426C14.9253 22.726 17.6253 25.426 20.9253 25.426C24.2253 25.426 26.9253 22.726 26.9253 19.426C26.9253 16.126 24.2253 13.426 20.9253 13.426ZM17.5824 22.7689C16.6824 21.8689 16.211 20.6689 16.211 19.5117L20.8824 24.1403C19.6824 24.0974 18.4824 23.6689 17.5824 22.7689ZM21.9539 24.0117L16.3396 18.3974C16.811 16.2974 18.6967 14.7117 20.9253 14.7117C22.511 14.7117 23.8824 15.4831 24.7396 16.6403L24.0967 17.1974C23.3682 16.2117 22.211 15.5689 20.9253 15.5689C19.2539 15.5689 17.8396 16.6403 17.2824 18.1403L22.211 23.0689C23.4539 22.6403 24.3967 21.5689 24.6967 20.2831H22.6396V19.426H25.6396C25.6396 21.6546 24.0539 23.5403 21.9539 24.0117Z"
        fill="#663399"
      />
      <defs>
        <linearGradient
          id={`${gradientId}`}
          x1="22.9174"
          y1="11.6882"
          x2="12.8026"
          y2="11.6882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#663399" />
          <stop offset="1" stopColor="#159BF3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
