import React from "react"

export const ConciergeIcon = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M22.3409 16.5909H18.0454V17.8182H20.9909C20.5614 19.6591 19.2114 21.1932 17.4318 21.8068L10.375 14.75C11.1114 12.6023 13.1977 11.0682 15.5909 11.0682C17.4318 11.0682 19.0886 11.9886 20.1318 13.4L21.0523 12.6023C19.825 10.9455 17.8614 9.84091 15.5909 9.84091C12.4 9.84091 9.69999 12.1114 9.02499 15.1182L17.125 23.2182C20.0704 22.4818 22.3409 19.7818 22.3409 16.5909Z"
      fill="white"
    />
    <path
      d="M8.84091 16.6523C8.84091 18.3705 9.51591 20.0273 10.8045 21.3159C12.0932 22.6046 13.8114 23.2796 15.4682 23.2796L8.84091 16.6523Z"
      fill="white"
    />
    <path
      d="M15.5909 8C10.8659 8 7 11.8659 7 16.5909C7 21.3159 10.8659 25.1818 15.5909 25.1818C20.3159 25.1818 24.1818 21.3159 24.1818 16.5909C24.1818 11.8659 20.3159 8 15.5909 8ZM10.8045 21.3773C9.51591 20.0886 8.84091 18.3705 8.84091 16.7136L15.5295 23.3409C13.8114 23.2795 12.0932 22.6659 10.8045 21.3773ZM17.0636 23.1568L9.025 15.1182C9.7 12.1114 12.4 9.84091 15.5909 9.84091C17.8614 9.84091 19.825 10.9455 21.0523 12.6023L20.1318 13.4C19.0886 11.9886 17.4318 11.0682 15.5909 11.0682C13.1977 11.0682 11.1727 12.6023 10.375 14.75L17.4318 21.8068C19.2114 21.1932 20.5614 19.6591 20.9909 17.8182H18.0455V16.5909H22.3409C22.3409 19.7818 20.0705 22.4818 17.0636 23.1568Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M19.4464 27.8891C21.5245 27.8891 22.4595 28.8286 22.4781 30.9335C22.4792 31.0583 22.5289 31.1777 22.6166 31.2665C22.7043 31.3553 22.8231 31.4064 22.9478 31.4091H22.9581C23.0813 31.4094 23.1999 31.3622 23.2893 31.2773C23.3787 31.1925 23.432 31.0765 23.4381 30.9534C23.5424 28.8631 24.5005 27.8891 26.4544 27.8891H26.4781C26.6054 27.8904 26.728 27.8411 26.819 27.7521C26.9099 27.663 26.9618 27.5415 26.9632 27.4142C26.9645 27.2869 26.9153 27.1642 26.8262 27.0733C26.7372 26.9823 26.6156 26.9304 26.4883 26.9291C24.4025 26.8849 23.4329 25.9217 23.4381 23.898V23.8903C23.4383 23.8273 23.4261 23.7649 23.4022 23.7066C23.3783 23.6483 23.3432 23.5953 23.2988 23.5506C23.2544 23.5059 23.2016 23.4704 23.1435 23.4461C23.0853 23.4218 23.023 23.4092 22.96 23.4091V23.4091C22.8969 23.4088 22.8345 23.421 22.7761 23.4449C22.7178 23.4688 22.6648 23.5039 22.62 23.5483C22.5753 23.5927 22.5397 23.6455 22.5153 23.7036C22.491 23.7617 22.4783 23.8241 22.4781 23.8871V23.8871C22.471 25.9339 21.5033 26.9003 19.431 26.9291C19.3037 26.93 19.182 26.9815 19.0926 27.0721C19.0033 27.1628 18.9536 27.2853 18.9545 27.4126C18.9555 27.5399 19.0069 27.6616 19.0976 27.751C19.1883 27.8403 19.3108 27.89 19.4381 27.8891H19.4464Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M26.2325 20.2464C27.7911 20.2464 28.4924 20.951 28.5063 22.5297C28.5071 22.6233 28.5444 22.7129 28.6102 22.7794C28.6759 22.846 28.7651 22.8844 28.8586 22.8864H28.8663C28.9587 22.8866 29.0477 22.8512 29.1147 22.7876C29.1818 22.7239 29.2217 22.6369 29.2263 22.5446C29.3045 20.9769 30.0231 20.2464 31.4885 20.2464H31.5063C31.6018 20.2474 31.6937 20.2104 31.762 20.1436C31.8302 20.0768 31.8691 19.9857 31.8701 19.8902C31.8712 19.7947 31.8342 19.7027 31.7674 19.6345C31.7006 19.5663 31.6095 19.5274 31.514 19.5264C29.9497 19.4932 29.2225 18.7708 29.2263 17.2531V17.2473C29.2265 17.2001 29.2174 17.1532 29.1994 17.1095C29.1815 17.0658 29.1551 17.026 29.1218 16.9925C29.0885 16.959 29.049 16.9323 29.0054 16.9141C28.9618 16.8959 28.915 16.8865 28.8677 16.8864C28.8205 16.8862 28.7736 16.8953 28.7299 16.9132C28.6861 16.9311 28.6463 16.9575 28.6128 16.9908C28.5792 17.0241 28.5525 17.0637 28.5342 17.1073C28.516 17.1509 28.5065 17.1976 28.5063 17.2449C28.501 18.78 27.7753 19.5048 26.221 19.5264C26.1255 19.5271 26.0342 19.5657 25.9672 19.6337C25.9002 19.7017 25.863 19.7935 25.8637 19.889C25.8644 19.9845 25.903 20.0758 25.971 20.1428C26.039 20.2098 26.1308 20.2471 26.2263 20.2464H26.2325Z"
      fill="#663399"
    />
    <path
      d="M4.30741 5.8C5.60621 5.8 6.19061 6.3872 6.20221 7.7028C6.20292 7.78078 6.23397 7.85542 6.28877 7.9109C6.34358 7.96638 6.41784 7.99834 6.49581 8H6.50221C6.57924 8.0002 6.65338 7.97072 6.70925 7.91768C6.76511 7.86465 6.7984 7.79213 6.80221 7.7152C6.86741 6.4088 7.46621 5.8 8.68741 5.8H8.70221C8.78177 5.80085 8.85841 5.77006 8.91527 5.7144C8.97214 5.65873 9.00456 5.58277 9.00541 5.5032C9.00625 5.42364 8.97546 5.34699 8.9198 5.29013C8.86414 5.23327 8.78817 5.20085 8.7086 5.2C7.40501 5.1724 6.79901 4.5704 6.80221 3.3056V3.3008C6.80236 3.26142 6.79476 3.2224 6.77982 3.18597C6.76489 3.14953 6.74291 3.1164 6.71516 3.08846C6.68741 3.06052 6.65443 3.03833 6.61809 3.02315C6.58176 3.00797 6.54278 3.00011 6.50341 3V3C6.46401 2.99984 6.42497 3.00745 6.38851 3.02238C6.35205 3.03731 6.31889 3.05927 6.29092 3.08702C6.26295 3.11477 6.24072 3.14775 6.2255 3.18409C6.21028 3.22043 6.20236 3.25941 6.20221 3.2988V3.2988C6.19781 4.578 5.59301 5.182 4.29781 5.2C4.21824 5.20058 4.14217 5.23275 4.08632 5.28942C4.03047 5.3461 3.99942 5.42264 4.00001 5.5022C4.00059 5.58177 4.03276 5.65784 4.08943 5.71369C4.14611 5.76954 4.22264 5.80058 4.30221 5.8H4.30741Z"
      fill="#159BF3"
      stroke="#159BF3"
      strokeWidth="0.306818"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="15.5909"
        y1="8"
        x2="15.5909"
        y2="21.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#159BF3" />
        <stop offset="1" stopColor="#663399" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="22.9589"
        y1="23.87"
        x2="22.9589"
        y2="31.4091"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.234375" stopColor="#663399" />
        <stop offset="1" stopColor="#159BF3" />
      </linearGradient>
    </defs>
  </svg>
)
