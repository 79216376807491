/** @jsx jsx */
import { jsx } from "@emotion/core"
import { HeaderUtilityNav } from "./PageHeader/HeaderUtilityNav"
import { BannerHeight } from "./shared/constants/layout"
import { MdWarning } from "react-icons/md"

const bannerCss = (theme, isInverted) => ({
  alignItems: `center`,
  borderBottomWidth: 0,
  borderBottomColor: isInverted
    ? theme.colors.whiteFade[10]
    : theme.colors.grey[20],
  display: `flex`,
  justifyContent: `center`,
  fontSize: theme.fontSizes[1],
  position: `relative`,
  width: "100%",

  [theme.mediaQueries.desktop]: {
    height: BannerHeight,
    marginLeft: 0,
    marginRight: 0,
  },
})

const innerContainerStyles = theme => ({
  display: `flex`,
  alignItems: `center`,
  backgroundColor: theme.colors.yellow[50],

  justifyContent: `center`,
  height: `100%`,
  width: "100%",

  borderBottomLeftRadius: theme.radii[3],
  borderBottomRightRadius: theme.radii[3],

  [theme.mediaQueries.desktop]: {
    whiteSpace: `nowrap`,
    minWidth: 0,
    width: "auto",
  },
})

const contentCss = theme => ({
  alignItems: "center",
  backgroundColor: theme.colors.yellow[50],
  color: theme.colors.blackFade[90],
  display: "flex",
  flexGrow: 1,
  marginLeft: `-${theme.space[6]}`,
  marginRight: `-${theme.space[6]}`,
  paddingLeft: theme.space[6],
  paddingRight: theme.space[6],
  paddingTop: theme.space[4],
  paddingBottom: theme.space[4],
  justifyContent: "center",
  overflowX: `auto`,
  width: "100%",
  textAlign: "center",

  p: {
    margin: 0,
  },

  a: {
    color: theme.colors.blackFade[90],
    fontWeight: theme.fontWeights.body,
    textDecoration: `underline`,
  },

  // sloppy hide the scrollbar
  "::-webkit-scrollbar": {
    width: 0,
    height: 0,
    background: "transparent",
  },

  [theme.mediaQueries.desktop]: {
    minWidth: 0,
    flexGrow: 0,
    width: "auto",
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: theme.space[6],
    marginRight: theme.space[6],

    p: {
      paddingRight: theme.space[6],
    },
  },
})

const iconCss = theme => ({
  color: theme.colors.blackFade[80],
  height: theme.fontSizes[2],
  margin: `0 ${theme.space[2]}`,
  verticalAlign: `sub`,
  width: theme.fontSizes[2],
})

const MarketingBanner = ({ isInverted, isDocs }) => {
  return (
    <aside
      className="banner"
      css={theme => bannerCss(theme)}
      aria-label="banner"
    >
      <div css={theme => innerContainerStyles(theme)}>
        <div css={theme => contentCss(theme)}>
          <p css={{ marginBottom: 0 }}>
            <MdWarning css={theme => iconCss(theme)} /> These are the docs for
            v2, which has an open{" "}
            <a href="https://github.com/gatsbyjs/gatsby/blob/d21fc26a811178e41dd3998e64b5946c2f7abc9d/README.md#contributing-to-gatsby-v2">
              maintenance window
            </a>{" "}
            until April 1, 2021.{" "}
            <a href="http://www.gatsbyjs.com/docs">View the v3 docs.</a>
          </p>
        </div>
      </div>
      <HeaderUtilityNav isInverted={isInverted} isDocs={isDocs} />
    </aside>
  )
}

export default MarketingBanner
