import React from "react"

export const SparkleIcon = ({ height = 16, width = 16, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1562 23.1562L12.5 29L9.84375 23.1562L4 20.5L9.84375 17.8438L12.5 12L15.1562 17.8438L21 20.5L15.1562 23.1562Z" fill="white" stroke="#663399" strokeWidth="1.5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.91 15.91L24.5 19L23.09 15.91L20 14.5L23.09 13.09L24.5 10L25.91 13.09L29 14.5L25.91 15.91Z" fill="#663399"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5967 7.59667L16.5 10L15.4033 7.59667L13 6.5L15.4033 5.40333L16.5 3L17.5967 5.40333L20 6.5L17.5967 7.59667Z" fill="#F67300"/>
    </svg>
  )
}
