import React from "react"

export const CloudDocumentation = ({ width = 32, height = 32, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <line
      y1="-0.75"
      x2="4.05674"
      y2="-0.75"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 8.2583 8.30344)"
      stroke="#159BF3"
      strokeWidth="1.5"
    />
    <circle
      r="6.01122"
      transform="matrix(-1 0 0 1 15.8013 15.4364)"
      fill="#F6EDFA"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <path
      d="M15.9433 16.1907H17.6877C17.5762 16.6775 17.3125 17.0223 17.0488 17.2251C16.6736 17.5091 16.1665 17.6308 15.7202 17.6308C15.0002 17.6308 14.5235 17.3468 14.2192 17.0527C13.9353 16.7789 13.5499 16.2414 13.5499 15.3388C13.5499 14.5781 13.844 13.929 14.2294 13.5538C14.6452 13.1481 15.2537 12.9554 15.7912 12.9554C16.1056 12.9554 16.4606 13.0264 16.7446 13.1684C16.9575 13.28 17.3024 13.5132 17.5356 13.9696L18.6816 13.351C18.3267 12.7424 17.85 12.3368 17.4139 12.1136C16.8967 11.8398 16.3592 11.7587 15.8521 11.7587C14.5438 11.7587 13.6919 12.2759 13.1848 12.7931C12.7791 13.1988 12.1909 14 12.1909 15.3083C12.1909 16.8296 12.9616 17.6815 13.1949 17.9148C13.9353 18.6551 14.8075 18.8275 15.6492 18.8275C16.491 18.8275 17.3531 18.6348 18.0529 17.9351C18.9859 17.0122 19.0468 15.5923 19.0468 15.1359V15.0548H15.9433V16.1907Z"
      fill="#663399"
    />
    <circle
      r="2.70449"
      transform="matrix(-1 0 0 1 27.2955 14.7603)"
      fill="#663399"
    />
    <line
      y1="-0.75"
      x2="4.05674"
      y2="-0.75"
      transform="matrix(-1 0 0 1 25.9434 15.4364)"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <circle
      r="1.95449"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 6.82473 7.82474)"
      stroke="#159BF3"
      strokeWidth="1.5"
    />
    <circle
      r="1.95449"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 7.06201 23.6155)"
      fill="#F1DEFA"
      stroke="#663399"
      strokeWidth="1.5"
    />
    <line
      y1="-0.75"
      x2="4.05674"
      y2="-0.75"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 7.54004 22.1805)"
      stroke="#663399"
      strokeWidth="1.5"
    />
  </svg>
)
